/*# ==============================================================================
# TunePad
#
# Northwestern University
# support@tunepad.com
# Copyright 2022, Michael S. Horn
#
# This project was funded by the National Science Foundation (grants 1612619 and
# 2119701). Any opinions, findings and conclusions or recommendations expressed
# in this material are those of the author(s) and do not necessarily reflect
# the views of the National Science Foundation (NSF).
# ==============================================================================*/

import { Privacy } from "./models/types";

const HOSTNAME = 'https://api.tunepad.com/api';
const LIVEDOMAIN = (window.location.hostname === "localhost" || 
                    window.location.hostname === "127.0.0.1" || 
                    window.location.hostname === 'community.tunepad.space') ? 'https://tunepad.space' : 'https://tunepad.com';


/*
  Users and Accounts
*/

async function connect()  {
  const response = await fetch(`${HOSTNAME}/connect/`, 
    { method: 'GET', 
      mode: 'cors',
      credentials: 'include', 
    }
  )
  return response;
}

async function userDetails(uid : string)  {
  let body = { 'user_id' : uid }

  const response = await fetch(`${HOSTNAME}/user-details/`, 
    { method: 'POST', 
      credentials: 'include', 
      mode: 'cors',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body) 
    }
  )
  return response
}

async function login(user : string, password : string)  {
  var body = {"username": user, "password": password};

  const response = await fetch(`${HOSTNAME}/login/`, 
    { method: 'POST', 
      //mode: 'cors',
      credentials: 'include', 
      body: JSON.stringify(body) 
    }
  )
  return response
}

/*async function register(username : string, email : string, password : string) {
  if (username === "") username = email;
  var jsonData = {"username": username, "email": email, "password": password};
  
  const response = await fetch(`${HOSTNAME}/register/`, 
    { method: 'POST', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(jsonData) 
    }
  )
  return response
}*/

async function logout()  {
  const response = await fetch(`${HOSTNAME}/logout/`, 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors'
      
    }
  )
  return response
}

async function adminNotificationCount()  {
  const response = await fetch(`${HOSTNAME}/admin/queue-counts`, 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
      headers: {'Content-Type': 'application/json'}
    }
  )
  return response
}


async function changeUserEmail( email : string )  {
  const response = await fetch(`${HOSTNAME}/update-email/`, 
    { method: 'POST', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({'new-email': email}) 
    }
  )
return response
}

async function retrievProfilePicture(filename : string, uid : string)  {
  let req = `${HOSTNAME}/retrieve-file/?url=${filename}`;
  const cacheStorage = await caches?.open('tunepad-user-pics');

  if (cacheStorage) {
    const cachedResponse = await cacheStorage.match(req);
    if (cachedResponse) return cachedResponse;
  }

  let body = {
    "uid": uid,
    "sub-dir": "profile",
    "filename": filename
  }

  const response = await fetch(req, 
    { method: 'POST', 
      credentials: 'include',
      body: JSON.stringify(body)  
    }
  )

  cacheStorage.put(req, response);
  return response
}

async function updatePrivacy(isPublic : boolean)  {
  let body = {"public" : isPublic};

  const response = await fetch(`${HOSTNAME}/profile/`, 
    { method: 'POST', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body) 
    }
  )
  return response
}

async function getFollowers(uid? : string) {
  if (!uid) {
    const response = await fetch(`${HOSTNAME}/followers/`, 
      { method: 'GET', 
        mode: 'cors',
        credentials: 'include', 
      }
    )
    return response;
  }
  else {
    let body = { 'user_id' : uid }

    const response = await fetch(`${HOSTNAME}/followers/`, 
    { method: 'POST', 
      mode: 'cors',
      credentials: 'include', 
      body: JSON.stringify(body) 
    }
  )
  return response;
  }
}

async function getFollowing() {
  const response = await fetch(`${HOSTNAME}/following/`, 
    { method: 'GET', 
      mode: 'cors',
      credentials: 'include', 
    }
  )
  return response;
}

async function follow(uid : string)  {
  let body = { 'user_id' : uid }

  const response = await fetch(`${HOSTNAME}/follower/add`, 
    { method: 'POST', 
      credentials: 'include', 
      body: JSON.stringify(body) 
    }
  )
  return response
}

async function unfollow(uid : string)  {
  let body = { 'user_id' : uid }

  const response = await fetch(`${HOSTNAME}/follower/remove/`, 
    { method: 'DELETE', 
      credentials: 'include', 
      body: JSON.stringify(body) 
    }
  )
  return response
}

/* 
  Posts
*/

async function getPost(pid : number) {
  const response = await fetch(`${HOSTNAME}/posts/${pid}/`, 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response
}

async function getAllPosts(page : number = 0, max : number = 25, search : string = '', sort = 'created') {
  if (search.trim() !== '') {
    const response = await fetch(`${HOSTNAME}/posts/search/?page=${page}&posts_per_page=${max}&search_str=${search}`, 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
      
    }
  )
  return response
  }

  const response = await fetch(`${HOSTNAME}/posts/public/?page=${page}&posts_per_page=${max}&sort=${sort}` + (search !== '' ? `&search=${search}` : ''), 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response
}

async function getUserPosts(uid : string, page : number = 0, max : number = 25) {
  let body = { 'user_id' : uid }

  const response = await fetch(`${HOSTNAME}/feed/?page=${page}&posts_per_page=${max}`,
    { method: 'POST', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(body) 
    }
  )
  return response
}

async function getUserFaves(page : number = 0, max : number = 25, search : string = '', sort = 'created') {
  const response = await fetch(`${HOSTNAME}/posts/faves/?page=${page}&projects_per_page=${max}&sort=${sort}` + (search !== '' ? `&search=${search}` : ''),  
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response
}

async function editPostContent(pid : number, content : string, tags : Array<string>) {
  let data = {"post_id": pid, "content": content, "tags": JSON.stringify({"custom-tags": tags}), "modified_timestamp" : new Date().toISOString()};
  const response = await fetch(`${HOSTNAME}/posts/${pid}/`, 
    { method: 'PUT', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(data) 
    }
  )
  return response
}

async function updateReactions(pid : number, updatedReactions : Array<any>) {
  let data = {"reactions" : updatedReactions, "post_id" : pid}
  const response = await fetch(`${HOSTNAME}/posts/reactions/`, 
    { method: 'PUT', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(data) 
    }
  )
  return response
}

async function approvePost(pid : number) {
  let data = {"post_id": pid, "show": true, "remove_from_queue": false}
  const response = await fetch(`${HOSTNAME}/posts/${pid}/`, 
    { method: 'PUT', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(data) 
    }
  )
  return response
}

async function ignorePost(pid : number) {
  let data = {"post_id": pid, "show": false, "remove_from_queue": true}
  const response = await fetch(`${HOSTNAME}/posts/${pid}/`, 
    { method: 'PUT', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(data) 
    }
  )
  return response
}

async function getHiddenPosts(page : number = 0, max : number = 25) {
  const response = await fetch(`${HOSTNAME}/posts/hidden/?page=${page}&posts_per_page=${max}`, 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response;
}

async function getIgnoredPosts(page : number = 0, max : number = 25) {
  const response = await fetch(`${HOSTNAME}/posts/ignored/?page=${page}&posts_per_page=${max}`,
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response;
}

async function deletePost(pid : number) {
  const response = await fetch(`${HOSTNAME}/posts/`, 
    { method: 'DELETE', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ 'post_id' : pid })  
    }
  )
  return response
}

async function like(id : number)  {
  let body = { 'post_id' : id }

  const response = await fetch(`${HOSTNAME}/post-stats/fave/`, 
    { method: 'POST', 
      credentials: 'include', 
      body: JSON.stringify(body) 
    }
  )
  return response
}

async function unlike(id : number)  {
  let body = { 'post_id' : id }

  const response = await fetch(`${HOSTNAME}/post-stats/unfave/`, 
    { method: 'DELETE', 
      credentials: 'include', 
      body: JSON.stringify(body) 
    }
  )
  return response
}

async function flagContent( id : number, reason : string, details : string )  {

  let body = {'post_id' : id, "flagged_reason" :  { 'type' : reason, 'long_description' : details}}
  
  const response = await fetch(`${HOSTNAME}/post-stats/flag/`, 
    { method: 'POST', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body) 
    }
  )
return response
}

async function getFlaggedPosts(page : number = 0, max : number = 25, search : string = '', sort = 'created') {
  const response = await fetch(`${HOSTNAME}/posts/flagged/?page=${page}&flags_per_page=${max}&sort=${sort}` + (search !== '' ? `&search=${search}` : ''), 
    { method: 'GET', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
    }
  )
  return response;
} 

async function ignoreAllFlags(pid : number) {
  const response = await fetch(`${HOSTNAME}/post-stats/reset-flags/`, 
    { method: 'DELETE', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({'post_id' : pid}) 
    }
  )
  return response;
} 

/* 
  Projects
*/

async function getAllProjects(page : number = 0, max : number = 25, search : string = '', sort = 'created') {
  const response = await fetch(`${HOSTNAME}/projects/public?page=${page}&projects_per_page=${max}&sort=${sort}` + (search !== '' ? `&search=${search}` : ''), 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response
}

async function getSelfProjects(page : number = 0, max : number = 25, search : string = '', sort = 'created') {
  const response = await fetch(`${HOSTNAME}/user-projects/?page=${page}&projects_per_page=${max}&sort=${sort}` + (search !== '' ? `&search=${search}` : ''),  
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response
}

async function getUserProjects(userID : string,page : number = 0, max : number = 25, search : string = '', sort = 'created') {
  const response = await fetch(`${HOSTNAME}/projects/public?username=${userID}&page=${page}&projects_per_page=${max}&sort=${sort}` + (search !== '' ? `&search=${search}` : ''),  
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response
}

async function featureProject(pid : number, ordering : string) {
  let body = {"project_id" : pid, "ordering" : ordering};
  const response = await fetch(`${HOSTNAME}/library/project`, 
    { method: 'POST', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(body) 
    }
  )
  return response
}

async function updateProject(fid : number, data : any) {
  const response = await fetch(`${HOSTNAME}/library/project/${fid}`, 
    { method: 'PUT', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(data) 
    }
  )
  return response
}

async function editProject(pid : number, data : any) {
  const response = await fetch(`${HOSTNAME}/project/${pid}/`, 
    { method: 'PUT', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(data) 
    }
  )
  return response
}

async function editProjectPrivacy(id : number, privacy : Privacy) {
  const response = await fetch(`${HOSTNAME}/projects/visibility`, 
    { method: 'PUT', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify({'project_id' : id, 'visibility' : privacy}) 
    }
  )
  return response
}

async function getFeaturedProjects(page : number = 0, max : number = 25, search : string = '', sort = 'created') {
  const response = await fetch(`${HOSTNAME}/library/project/featured?page=${page}&projects_per_page=${max}&sort=${sort}` + (search !== '' ? `&search=${search}` : ''), 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response
}

/* 
  Library
*/

async function getLibrary(page : number = 0, sort = 'modified')  {
  const response = await fetch(`${HOSTNAME}/library/track/search?page=${page}&filter=bass,drums,drumkit,guitar,piano,marimba,sequencer,definitions&sort=${sort}`, 
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors',
    }
  )
  return response
}

async function editLibraryTrack(id : number, data : any)  {
  const response = await fetch(`${HOSTNAME}/library/track/${id}`, 
    { method: 'PUT', 
      credentials: 'include', 
      mode: 'cors',
      body: JSON.stringify(data)  
    }
  )
  return response
}

/*
  Misc. utils
*/

async function getAudioData(url : string) {
  const response = await fetch(url, 
    { method: 'GET', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
    }
  )
  return response
}

async function getUserFeed(page : number = 0, max : number = 25) {
  const response = await fetch(`${HOSTNAME}/feed/?page=${page}&posts_per_page=${max}`,
    { method: 'GET', 
      credentials: 'include', 
      mode: 'cors'
    }
  )
  return response
}

async function createTestPost()  {
  let body = {
    "content": "Dolore magna aliqua. Annim id est laborum.",
    "project_preview_link": "https://tunepad.com/preview/hello",
    "tags": "{\"genre\": \"smooth jazz\", \"custom-tags\": \"cool dude, test test, test!\" }"
  }
  const response = await fetch(`${HOSTNAME}/posts/`, 
    { method: 'POST', 
      credentials: 'include', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body) 
    }
  )
  return response
}

async function uploadFile(form : FormData)  {
  const response = await fetch(`${HOSTNAME}/upload-file/`, 
    { method: 'POST', 
      //headers: {'Content-Type': 'multipart/form-data'},
      credentials: 'include', 
      body: form
    }
  )
  return response
}


export { login, logout, userDetails, connect, adminNotificationCount, retrievProfilePicture, uploadFile, getSelfProjects, getUserFaves,getUserProjects, updatePrivacy, editProjectPrivacy,
         getFeaturedProjects, changeUserEmail, getUserFeed, getUserPosts, getAllPosts, editPostContent, getAllProjects, getPost, deletePost, getAudioData, 
         getFollowers, follow, unfollow, getFollowing, like, unlike, flagContent, featureProject, editProject, getLibrary, editLibraryTrack, 
         approvePost, ignorePost, getFlaggedPosts, ignoreAllFlags, updateProject, getHiddenPosts, getIgnoredPosts, createTestPost, updateReactions,
         LIVEDOMAIN, HOSTNAME };
